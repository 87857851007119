// API
import BaseAPI from './base';

/**
 * Implement Discount API
 * implementar la API de descuento
 */
export default class DiscountAPI extends BaseAPI {
  constructor() {
    super('discounts');
  }

  getByCode(params) {
    return this.request.get(`${this.baseURI}/get-by-code`, { params });
  }
}
